import React from 'react';
import _ from 'lodash';
import { useStyles } from '../../containers/order/styles';
import RadioButton from '../ui/components/RadioButton/RadioButton';
import { getImgFromUploads } from '../../lib/getImage';
import { B2C, SPT, UNT, UZC, EPA, VSL, PDC, DCD, IBT, ALU } from '../../containers/order/constants';
import UnitellerSecurityInfo from '../staticInfo/unitellerSecurityInfo';
import useLocalization from '../../contexts/localization/hook';
import { useSelector } from 'react-redux';

export default function PaymentMethods(props) {
    const classes = useStyles();
    const { t, language } = useLocalization();
    const { orderData } = useSelector(state => state.setOrderData);
    const { balance } = useSelector(state => state.clientData);
    const { onChange, checkedPaymentMethod, methodData } = props;
    const { code, logo, description } = methodData;

    const PAYMENT_METHODS_DATA = [
        { code: B2C, namePaymentLabel: t.payment_with_dpt },
        { code: SPT, namePaymentLabel: t.payment_with_spt },
        { code: UNT, namePaymentLabel: t.payment_with_unt },
        { code: UZC, namePaymentLabel: t.payment_with_uzc },
        { code: VSL, namePaymentLabel: t.payment_with_vsl },
        { code: EPA, namePaymentLabel: t.payment_with_vsl },
        { code: PDC, namePaymentLabel: t.payment_with_vsl },
        { code: DCD, namePaymentLabel: t.payment_with_vsl },
        { code: IBT, namePaymentLabel: t.payment_with_vsl },
        // { code: ALU, namePaymentLabel: t.payment_with_vsl },
    ];

    const method = _.find(PAYMENT_METHODS_DATA, { code: code });

    if (_.isUndefined(method)) {
        return null;
    }

    const paymentMethodLabel = description[language] ? description[language] : method.namePaymentLabel;

    const handelOnChange = () => {
        if (code !== checkedPaymentMethod) {
            if (code !== B2C) {
                onChange(code);
            }

            if (code === B2C && checkEnoughBalance()) {
                onChange(code);
            }
        }
    };

    const checkEnoughBalance = () => {
        return parseFloat(balance) >= parseFloat(orderData.total_price);
    };

    return (
        <div className={classes.cardPaymentContainer}>
            <div className={classes.methodBlock}>
                <span component="span" className={classes.radioButtonBlock}>
                    <RadioButton onChange={handelOnChange} checked={checkedPaymentMethod === code} />
                </span>
                <span component="span" className={classes.methodTitle}>
                    {paymentMethodLabel}
                    {code === UNT && (
                        <div className={classes.securityInfoBlock}>
                            <UnitellerSecurityInfo isOpen={true} />
                        </div>
                    )}
                    {code === B2C && !checkEnoughBalance() && (
                        <div className={classes.notEnoughBalanceText}>{t.not_enough_balance}</div>
                    )}
                </span>
            </div>
            <MethodLogo methodLogo={logo} />
        </div>
    );
}

const MethodLogo = props => {
    const classes = useStyles();
    const { methodLogo } = props;
    const imgUrl = getImgFromUploads(methodLogo);

    if (methodLogo) {
        return <img className={classes.paymentMethodLogo} alt={'t.your_logo'} src={imgUrl} height="94px" />;
    }

    return null;
};
