import React from "react";

import useLocalization from "../../../../contexts/localization/hook";
import { BaggageAndHandLuggageInfoBlock } from "./component";
import Grid from "../grid";

const BaggageAndHandLuggageUI = ({ baggage, handLuggage }) => {
  const { t } = useLocalization();

  console.log(1111, baggage, handLuggage);


  const renderLoadValue = (load, loadText, noLoad) => {
    const lowerLetters = typeof load !== "number" && load?.toLowerCase();
    const loadValue = parseInt(load);
    const isPC = lowerLetters !== false && lowerLetters?.match(/pc/)?.[0];    

    if (isNaN(loadValue)) {
      return (
        <BaggageAndHandLuggageInfoBlock>
          {loadText}: {load}
        </BaggageAndHandLuggageInfoBlock>
      );
    }

    if (isPC === "pc") {
      const quantityOfPC = load.slice(3);

      return (
        <BaggageAndHandLuggageInfoBlock>
          {loadText}:{" "}
          {quantityOfPC !== " "
            ? `${loadValue}✕${quantityOfPC}`
            : `${loadValue} ${loadValue === 1 ? t.place_one : t.place.many}`}
        </BaggageAndHandLuggageInfoBlock>
      );
    } else if (loadValue === 0) {
      return <BaggageAndHandLuggageInfoBlock>{noLoad}</BaggageAndHandLuggageInfoBlock>
    } else {
      return (
        <BaggageAndHandLuggageInfoBlock>
          {loadText}: {loadValue} {loadValue === 1 ? t.place_one : loadValue < 5 ? t.place_many : t.weight_unit}
        </BaggageAndHandLuggageInfoBlock>
      )
    }
  };

  return (
    <>
      <Grid item xs={12}>
        {renderLoadValue(baggage, t.baggage, t.no_baggage)}
      </Grid>
      <Grid item xs={12}>
        {renderLoadValue(handLuggage, t.hand_luggage, t.no_hand_luggage)}
      </Grid>
    </>
  )
};

export default BaggageAndHandLuggageUI;
