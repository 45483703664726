import styled from "styled-components";

const textColor = "#4D4D4D";
const primaryColor = "#4EA6BF";
const secondaryColor = "#FFB263";

export const InfoWrapper = styled("div")(({ theme }) => ({
  "section:nth-child(1)": {
    h2: {
      color: secondaryColor,
      fontSize: 36,

      "@media screen and (max-width: 600px)": {
        fontSize: 26
      }
    },
  },
}));

export const Section = styled("section")(({ theme }) => ({
  margin: "80px 0",

  "@media screen and (max-width: 600px)": {
    margin: "60px 0"
  }
}));

export const H2 = styled("h2")(({ theme }) => ({
  color: primaryColor,
  fontWeight: "bold",
  fontSize: 26,
  margin: "10px 0",

  "@media screen and (max-width: 600px)": {
    fontSize: 20
  }
}));

export const H3 = styled("h3")(({ theme }) => ({
  color: textColor,
}));

export const Paragraph = styled("p")(({ theme }) => ({
  color: textColor,
  margin: "0 0 16px 0",
}));

export const List = styled("li")(({ theme }) => ({
  color: textColor,
  marginBottom: 16,
}));

export const GeneralProvisions = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "repeat(3, 1fr)",
  gap: 20,

  "div:nth-child(1)": {
    gridColumn: "1/3",
  },

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",

    "div:nth-child(1)": {
      gridColumn: "1/2",
    },
  },
}));

export const Number = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 4,
  left: 4,
  width: 80,
  height: 80,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#0000000d",
  fontSize: 64,
  color: "#0000001a",

  "@media screen and (max-width: 600px)": {
    width: 60,
    height: 60,
    fontSize: 44,
  },
}));

export const GeneralProvisionItems = styled("div")(({ theme }) => ({
  padding: "50px 20px 20px 60px",
  boxShadow: "2px 2px 12px 0px #0000001a",
  borderRadius: "44px 16px 44px 16px",
  position: "relative",

  "@media screen and (max-width: 600px)": {
    padding: "30px 10px 10px 40px",
    borderRadius: "34px 10px 34px 10px",
  },
}));

export const Definition = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: 16,

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media screen and (max-width: 600px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const DefinitionItemsWrap = styled("div")(({ theme }) => ({
  backgroundColor: secondaryColor,
  borderRadius: 16,
  position: "relative",
  aspectRatio: "1/1",

  ":hover": {
    div: {
      width: 100,
      height: 100,
      transform: "translateY(-50%)",
      margin: "0 auto",
      borderRadius: "50%",
      boxShadow: '0px 0px 3px 0px #000',

      img: {
        width: 50,
        height: 50
      },

      h3: {
        display: "none"
      }
    }
  },
}))

export const DefinitionItems = styled("div")(({ theme }) => ({
  backgroundColor: primaryColor,
  borderRadius: 16,
  aspectRatio: "1/1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: 16,
  textAlign: "center",
  gap: 3,
  transition: ".3s",
  position: "relative",
  zIndex: 10,

  h3: {
    color: "#fff",
    margin: 0,
    fontWeight: 400,
  },

  "@media screen and (max-width: 1280px) and (min-width: 960px)": {
    img: {
      width: 60,
    },

    h3: {
      fontSize: 16,
    },
  },
}));

export const Description = styled("article")(({ theme }) => ({
  position: "absolute",
  top: 0,
  padding: "60px 10px 10px 10px",
  overflow: "auto",
  width: "100%",
  height: "100%"
}))

export const OfferItems = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",

  img: {
    width: "100%",
  },

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "1fr"
  }
}));

export const ImageMultiply = styled("img")(({ theme, index }) => ({
  mixBlendMode: "multiply",
  gridColumn: index === 1 ? "2/3" : null,
  gridRow: index === 1 ? "1/2" : null,

  "@media screen and (max-width: 960px)": {
    gridColumn: "1/2"
  }
}));

export const IconAndTextListWrap = styled("div")(({ theme }) => ({
  position: "relative",
  marginBottom: 48
}));

export const IconAndTextList = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 22,
  marginBottom: 24,
  position: "relative",
}));

export const StickyIcon = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "70%",
  height: "100%",
}));

export const ImgBlock = styled("div")(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: "50%",
  border: `2px solid ${secondaryColor}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  padding: 8,

  img: {
    width: "100%",
    aspectRatio: 1 / 1,
  },
}));

export const DashedLine = styled("div")(({ theme }) => ({
  height: "100%",
  border: `2px dashed ${secondaryColor}`,
  position: "absolute",
  left: 29,
}));

export const HotelSectionsWrapper = styled("div")(({ theme }) => ({
  "img:nth-child(2)": {
    gridColumn: "2/3",
  },
}));

export const HotelSections = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: 32,
  margin: "48px 0",

  img: {
    width: "100%",
  },

  "@media screen and (max-width: 1280px)": {
    alignItems: "center"
  },

  "@media screen and (max-width: 960px)": {
    gridTemplateColumns: "1fr"
  }
}));

export const HotelTextBlock = styled("div")(({ theme, index }) => ({
  gridColumn: index === 1 ? "1/2" : null,
  gridRow: index === 1 ? "1/2" : null,

  "@media screen and (max-width: 960px)": {
    gridRow: "2/3"
  }
}));

export const ListSection = styled("div")(({ theme }) => ({
  margin: "48px 0"
}))